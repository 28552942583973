
<template>
  <div v-if="items">
    <card-loading-transparent v-if="loading"></card-loading-transparent>
      <v-card flat v-if="!loading">
      <v-card-title v-if="!items.length">Adicione um campo para exibir.</v-card-title>
      <v-card-title v-if="items.length" class="d-flex justify-end pt-4">
        <v-btn class="mr-3 mt-1" small rounded v-if="form_id" :disabled="is_editable"
          :color="is_draggable_editable ? 'error' : 'success'" @click="is_draggable_editable=!is_draggable_editable"
        >
          <v-icon left>{{is_draggable_editable ? 'mdi-cancel' : 'mdi-pencil'}}</v-icon>
          {{is_draggable_editable ? 'Cancelar' : 'Reorganizar itens'}}
        </v-btn>
        <v-btn class="mr-3 mt-1" color="primary" :disabled="is_editable" small rounded v-if="is_draggable_editable" @click="updateItemsIndexOrder()">
          <v-icon left>mdi-plus</v-icon>Salvar posições
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="newItem" :readonly="!is_editable">
          <v-expansion-panels class="d-block" focusable :value="expansion_panel_value">
            <draggable v-model="items" @change="setItemIndexOrder" 
              :disabled="!is_draggable_editable || is_editable" :move="closePanelAnimation"
            >
              <transition-group name="flip-transition">
                <v-expansion-panel v-for="(element, index) in items" :key="index" 
                  :readonly="is_editable" @click="alertDialog"
                >
                  <v-expansion-panel-header disable-icon-rotate>
                    <div>
                      <v-icon>mdi-drag</v-icon> 
                      {{index+1}} - {{element.title.substring(0,40)}}<span v-if="element.title.length > 40">...</span>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="pt-9">
                      <v-col cols="12" class="py-0">
                        <v-text-field outlined dense prepend-inner-icon="mdi-card-text-outline" color="primary" 
                          label="Título da pergunta" v-model="element.title" :rules="required"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-textarea rows="2" outlined dense prepend-inner-icon="mdi-format-list-text" color="primary" 
                          label="Descrição da pergunta (opcional)" v-model="element.description"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-select dense outlined label="Categoria de resposta" v-model="element.kind"
                          :rules="required" :item-value="'value'" :items="kind" :item-text="'title'"
                        >
                          <template v-slot:[`item`]="menu">
                            <span class="py-2">
                              <v-icon class="mr-3">{{menu.item.icon}}</v-icon>{{menu.item.title}}
                            </span>
                          </template>
                          <template v-slot:selection="show">
                            <v-icon class="mr-3">{{show.item.icon}}</v-icon>{{show.item.title}}
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" class="py-0" align="center">
                        <div v-if="element.kind == 'radio' || element.kind == 'dropdown' || element.kind == 'checkbox' ">
                          <div style="border: 1px solid #ccc!important" class="pa-3">
                            <div class="primary--text subtitle-1 mb-2 d-flex justify-start">Opções de resposta: </div>
                            <div class="my-1 d-flex" v-for="(el, idx) in element.values" :key="idx" >
                              <v-text-field v-model="element.values[idx]" :label="`Opção ${idx+1}`" 
                                :prepend-icon="element.kind == 'radio' ? 'mdi-radiobox-marked' 
                                : element.kind == 'dropdown' ? 'mdi-arrow-down-drop-circle' : 'mdi-checkbox-outline'"
                                :rules="required"
                              >
                              </v-text-field>
                              <v-btn class="mb-auto mt-auto" icon color="normal" rounded
                                :disabled="!is_editable" @click="removeItemValues(element.values, idx)"
                              >
                                <v-icon size="22px">mdi-delete</v-icon>
                              </v-btn>
                            </div>
                            <v-btn small color="success" rounded 
                              :disabled="!is_editable" @click="addItemValues(element)"
                            >
                              <v-icon left>mdi-plus</v-icon> Alternativa
                            </v-btn>
                            <v-select
                              :items="other_value_options"
                              v-model="element.other_value"
                              :item-value="'value'"
                              :item-text="'title'"
                              label="Opção de resposta — Outro"
                              outlined
                              dense
                              class="px-0 pt-3"
                              :prepend-icon="element.kind == 'radio' ? 'mdi-radiobox-marked' : element.kind == 'dropdown' ? 'mdi-arrow-down-drop-circle' : 'mdi-checkbox-outline'"
                            ></v-select>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="py-2 mt-6"/>
                    <v-row class="pt-0">
                      <v-col cols="12" md="7" class="pt-1">
                        <div v-if="element.kind != 'divider'" class="d-flex">
                          <v-checkbox  prepend-inner-icon="mdi-map-marker" class="mx-5" 
                            label="Adicionar — Não se aplica" v-model="element.has_not_apply" 
                          ></v-checkbox>
                          <v-switch prepend-inner-icon="mdi-map-marker" class="mx-5" 
                            label="Resposta obrigatória" v-model="element.required"
                          ></v-switch>
                        </div>
                      </v-col>
                      <v-col class="d-flex justify-end flex-wrap" align-self="center" cols="12" md="5">
                        <v-btn class="mr-3 mt-1" small color="red" rounded dark 
                          v-if="!is_editable || !element.id" @click="deleteItem(element, index)"
                        >
                          <v-icon left>mdi-delete</v-icon> Excluir
                        </v-btn>
                        <v-btn class="mr-3 mt-1" small  rounded 
                          :color="is_editable ? 'error' : 'success'" v-if="element.id" @click="editItem(index)"
                        >
                          <v-icon left>{{is_editable ? 'mdi-cancel' : 'mdi-pencil'}}</v-icon>
                          {{is_editable ? 'Cancelar' : 'Editar'}}
                        </v-btn>
                        <v-btn class="mr-3 mt-1" color="primary" rounded small @click="saveItem(element, index)" v-if="is_editable">
                          <v-icon left>mdi-plus</v-icon>Salvar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </transition-group>
            </draggable>
          </v-expansion-panels>
        </v-form>
      </v-card-text>
    </v-card>
    <v-col cols="12" v-if="!loading">
      <div class="d-flex justify-center">
        <v-btn color="primary" :disabled="is_editable" @click="addFormItem" rounded>
          <v-icon left>mdi-plus</v-icon>Adicionar campo ao formulário
        </v-btn>
      </div>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'
import draggable from 'vuedraggable'
import validations from '@/mixins/validations';

export default {
  name: 'FieldsForm',
  props: {
    form_id_aux: {
      type: Number,
      default: null
    }
  },
  mixins: [
    validations
  ],
  components: {
    draggable,
  },
  data() {
    return {
      kind: [
        {value: 'text', title: 'Resposta curta', icon: 'mdi-text-short'},
        {value: 'textarea', title: 'Parágrafo', icon: 'mdi-text-long'},
        {value: 'radio', title: 'Múltipla escolha', icon: 'mdi-radiobox-marked'},
        {value: 'dropdown', title: 'Lista suspensa', icon: 'mdi-arrow-down-drop-circle'},
        {value: 'checkbox', title: 'Caixa de seleção', icon: 'mdi-checkbox-outline' },
        {value: 'rating', title: 'Avaliação — (escala)', icon: 'mdi-star'},
        {value: 'divider', title: 'Divisor — (Titulo e descrição das próximas perguntas)', icon: 'mdi-card-text-outline'},
        {value: 'file', title: 'Upload de arquivo', icon: 'mdi-paperclip'},  
        {value: 'date', title: 'Seleção de data', icon: 'mdi-calendar'},  
      ],
      other_value_options: [
        {value: 'none_option', title: 'Não exibir opção outro'},
        {value: 'only_option', title: 'Exibir opção outro'},
        {value: 'option_and_input', title: 'Exibir opção outro com campo para preenchimento.'}
      ],
      is_editable: false,
      is_draggable_editable: false,
      expansion_panel_value: -1,
      editing_item_aux: {},
      loading: false,
      items_updated: [],
      items: [],
      form_id: null,
    }
  },
  methods: {
    
    addFormItem() {
      let item = {
        id: null,
        kind: '',
        title: '',
        description: '',
        values: [''],
        other_value: null,
        index_order: null,
        visible: true,
        required: false,
        has_not_apply: false
      }
      this.items.push(item)

      let created_item_index = this.items.length-1
      this.items[created_item_index].index_order = created_item_index
      this.expansion_panel_value = created_item_index
      this.is_editable = true
    },
    removeItemValues(values, idx){
      values.splice(idx, 1)
    },
    addItemValues(element){
      element.values.push('')
    },
    saveItem(element, index){
      if (this.$refs.newItem.validate()) {
        this.saveDialog().then((r) => {
          if(r.isConfirmed){
            let kind_item_aux = this.items[index].kind
            if(kind_item_aux != 'radio' && kind_item_aux != 'dropdown' && kind_item_aux != 'checkbox' ){
              this.items[index].values = []
              this.items[index].other_value = 'none_option'
            }
            if(!element.id){
              let item_aux = {
                form_id: this.form_id,
                ...element
              }
              Api.Item.createByForm(item_aux, this.form_id).then(r => r.data).then(r => {
                this.items[index].id = r.id,
                this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
              }).catch(err => {
                this.addNotification({type: 'error', msg: `Erro: ${err}`})
              })
            }else{
              Api.Item.update(element).then(() => {
                this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
              }).catch(err => {
                this.addNotification({type: 'error', msg: `Erro: ${err}`})
              })
            }
            this.expansion_panel_value=-1
            this.is_editable = false
          }
        })
      }
    },  
    deleteItem(element, index){
      this.saveDialog().then((r) => {
        if(r.isConfirmed){
          element = {
            ...element,
            visible: false
          }
          if(element.id){
            Api.Item.update(element).then(r => r.data).then(() => {
              this.items.splice(index, 1)
              this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
            }).catch(err => {
              this.addNotification({type: 'error', msg: `Erro: ${err}`})
            })
          }else{
            this.items.splice(index, 1)
          }
          this.expansion_panel_value=-1
          this.is_editable = false
        }
      })
    },
    editItem(index){
      this.is_draggable_editable = false
      if(!this.is_editable){
        this.editing_item_aux = {
          ...this.items[index]
        }
        this.is_editable = true
      }else{
        this.items[index] = this.editing_item_aux
        this.is_editable = false
        this.editing_item_aux = {}
      }

      if(this.items[index].values.length < 1){
        this.addItemValues(this.items[index])
      }
      
    },
    closePanelAnimation(){
      this.expansion_panel_value = -1
    },
    setItemIndexOrder(evt){
      let arr = [evt.moved.oldIndex, evt.moved.newIndex].sort()
      let aux_items_updated = this.items.filter((item, idx)=> idx >= arr[0] && idx <= arr[1] ).map((item, idx) => {
        let {id, index_order} = item
        this.items[arr[0]+idx].index_order = arr[0]+idx

        if(this.items_updated.find(obj => {return obj.id == item.id}) != undefined){
          this.items_updated.find(obj => {return obj.id == item.id}).index_order = arr[0]+idx
          return null
        }else{
          return {id, index_order: arr[0]+idx}
        }
      })
      this.items_updated = [...this.items_updated, ...aux_items_updated.filter(obj=> obj != null)]
      this.expansion_panel_value--
    },
    updateItemsIndexOrder(){
      this.saveDialog().then((r) => {
        if(r.isConfirmed){
          let form_aux = {
            id: this.form_id,
            items_attributes: this.items_updated
          }
          Api.Form.update(form_aux).then(()=> {
            this.addNotification({type: 'success', msg: 'Realizado com sucesso'})
            this.is_draggable_editable = false
            this.loading = true
            setTimeout(() => {
              this.loading = false
            }, 300);
          }).catch(err => {
            this.addNotification({type: 'error', msg: `Erro: ${err}`})
          })
        }
      })
    },

    alertDialog(){
      if(this.is_editable){
        this.$swal({
          showConfirmButton: true,
          showCancelButton: false,
          cancelButtonText: 'Cancelar',
          title: 'Finalize o item atual para prosseguir',
          icon: 'error',
        })
      }
    },
    saveDialog(){
      return this.$swal({
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        title: 'Deseja realizar operação?',
        icon: 'question',
      })
    },
    getItems(){
      this.form_id = this.$route.params.id ? this.$route.params.id : this.form_id_aux
      let filter = {
        visible: true,
      }
      Api.Item.indexByForm(this.form_id, filter).then(r=>r.data).then(d=> {
        setTimeout(()=>{
          this.setItems(d)
        }, 600)
      }).catch((err) => {
        console.log(err);
      })
    },
    setItems(items){
      this.items = [...items]
      this.orderItems()
    },
    orderItems(){
      if(this.items){
        let aux_order = {}
        let i = 0
        this.count = 0
        this.loading = true
        for(i=0;i < this.items.length;i++){
          if(this.items[i+1]){
            if(this.items[i].index_order > this.items[i+1].index_order){
              aux_order = this.items[i+1]
              this.items[i+1] = this.items[i]
              this.items[i] = aux_order
              this.count++
            }
          }
        }
        if(this.count > 0){
          this.orderItems()
        }else{
          this.loading = false
        }
      }
    },
    ...mapActions({
      addNotification: 'Notification/add'
    })
  },
  computed: {
    name() {
      return null 
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
  mounted() {
    this.getItems()
  },
}
</script>

<style scoped>

</style>